import { SERVICE_METHODS } from "./AppEnumerations";

export const API_ENDPOINTS = {
  //-------------authentication--------------------------------
  log_in: { url: `account`, method: SERVICE_METHODS.POST },
  verify_otp: { url: `verify-otp`, method: SERVICE_METHODS.POST },
  crypto: { url: `cpr`, method: SERVICE_METHODS.POST_CONFIG },

  //-------------service table---------
  get_all_services: { url: `service`, method: SERVICE_METHODS.GET },
  delete_service_by_id: { url: `service`, method: SERVICE_METHODS.DELETE },
  active_inactive_service: {
    url: `serviceStatus`,
    method: SERVICE_METHODS.POST,
  },
  //---------------basic information--------------------------------
  update_basic_info_service: { url: `service`, method: SERVICE_METHODS.PUT },
  add_basic_info_service: { url: `service`, method: SERVICE_METHODS.POST },
  get_service_subcategory_by_id: {
    url: `service-subcategory`,
    method: SERVICE_METHODS.GET,
  },
  // ---------------------------------------sub category --------------------------------
  get_service_subcategory: {
    url: `service-subcategory`,
    method: SERVICE_METHODS.GET,
  },
  add_service_sub_category: {
    url: `service-subcategory`,
    method: SERVICE_METHODS.POST,
  },
  get_service_sub_category_by_id: {
    url: `service-subcategoryById`,
    method: SERVICE_METHODS.GET,
  },

  update_service_sub_category: {
    url: `service-subcategory`,
    method: SERVICE_METHODS.PUT,
  },
  delete_service_sub_category: {
    url: `service-subcategory`,
    method: SERVICE_METHODS.DELETE,
  },
  active_deactivate_service_sub_category: {
    url: `serviceSubcategory-status`,
    method: SERVICE_METHODS.POST,
  },
  // --------------------------service category -------------
  get_service_category: {
    url: `service-category`,
    method: SERVICE_METHODS.GET,
  },
  get_service_category_by_id: {
    url: `service-category`,
    method: SERVICE_METHODS.GET,
  },
  get_interval_units: {
    url: `interval-units`,
    method: SERVICE_METHODS.GET,
  },

  add_service_category: {
    url: `service-category`,
    method: SERVICE_METHODS.POST,
  },

  update_service_category: {
    url: `service-category`,
    method: SERVICE_METHODS.PUT,
  },

  delete_service_category: {
    url: `service-category`,
    method: SERVICE_METHODS.DELETE,
  },

  active_deactivate_service_category: {
    url: `serviceCategory-status`,
    method: SERVICE_METHODS.POST,
  },

  // ----------------------------------Faqs -------------------------------
  get_all_faqs: {
    url: `faqs`,
    method: SERVICE_METHODS.GET,
  },

  add_faqs: {
    url: `faqs`,
    method: SERVICE_METHODS.POST,
  },
  update_faqs: {
    url: `faqs`,
    method: SERVICE_METHODS.PUT,
  },
  delete_faqs: {
    url: `faqs`,
    method: SERVICE_METHODS.DELETE,
  },

  active_faqs: {
    url: `faqs-status`,
    method: SERVICE_METHODS.POST,
  },
  fetch_upload_image: {
    url: `upload-image`,
    method: SERVICE_METHODS.POST,
  },

  get_service_faqs: {
    url: `svcfaq`,
    method: SERVICE_METHODS.GET,
  },
  add_service_faqs: {
    url: `service-faqs`,
    method: SERVICE_METHODS.POST,
  },

  get_all_service_faqs: {
    url: `svcfaq`,
    method: SERVICE_METHODS.GET,
  },
  delete_service_faqs: {
    url: `service-faqs`,
    method: SERVICE_METHODS.DELETE,
  },
  // ----------------------------Highlights---------------------//
  get_all_highlights: {
    url: `highlights`,
    method: SERVICE_METHODS.GET,
  },
  post_highlights: {
    url: `highlights`,
    method: SERVICE_METHODS.POST,
  },
  update_highlights: {
    url: `highlights`,
    method: SERVICE_METHODS.PUT,
  },
  get_highlights_by_id: {
    url: `highlights`,
    method: SERVICE_METHODS.GET,
  },
  delete_highlights: {
    url: `highlights`,
    method: SERVICE_METHODS.DELETE,
  },
  active_inactive_highlights: {
    url: `highlights`,
    method: SERVICE_METHODS.POST,
  },

  //----------------Booking Cancellation------------------------

  get_all_booking_cancellation: {
    url: `booking-cancellationCharges`,
    method: SERVICE_METHODS.GET,
  },
  post_booking_cancelation: {
    url: `booking-cancellationCharges`,
    method: SERVICE_METHODS.POST,
  },
  update_booking_cancelation: {
    url: `booking-cancellationCharges`,
    method: SERVICE_METHODS.PUT,
  },
  get_all_service_title: {
    url: `service`,
    method: SERVICE_METHODS.GET,
  },
  delete_booking_cancellation: {
    url: `booking-cancellationCharges`,
    method: SERVICE_METHODS.DELETE,
  },

  active_inactive_booking_cancellation: {
    url: `booking-cancellationCharges`,
    method: SERVICE_METHODS.POST,
  },

  // ------------------------------ library ---------------------
  get_all_library_directories: {
    url: `lib`,
    method: SERVICE_METHODS.GET,
  },
  get_library_directories_contend: {
    url: `ldc`,
    method: SERVICE_METHODS.POST,
  },
  // ---ld---
  create_folder: {
    url: `ld`,
    method: SERVICE_METHODS.POST,
  },

  get_by_folder: {
    url: `ld`,
    method: SERVICE_METHODS.GET,
  },

  Update_folder: {
    url: `ld`,
    method: SERVICE_METHODS.PUT,
  },

  delete_folder: {
    url: `ld`,
    method: SERVICE_METHODS.DELETE,
  },

  active_folder: {
    url: `ld`,
    method: SERVICE_METHODS.POST,
  },
  // ---lc---
  create_file: {
    url: `lc`,
    method: SERVICE_METHODS.POST,
  },

  get_by_file: {
    url: `lc`,
    method: SERVICE_METHODS.GET,
  },

  Update_file: {
    url: `lc`,
    method: SERVICE_METHODS.PUT,
  },

  delete_file: {
    url: `lc`,
    method: SERVICE_METHODS.DELETE,
  },
  active_file: {
    url: `lc`,
    method: SERVICE_METHODS.POST,
  },

  //----------------------Course Content------------------------

  get_all_course_content: {
    url: `service-courseContent`,
    method: SERVICE_METHODS.GET,
  },

  post_course_content: {
    url: `service-courseContent`,
    method: SERVICE_METHODS.POST,
  },
  update_course_content: {
    url: `service-courseContent`,
    method: SERVICE_METHODS.PUT,
  },
  delete_course_content_by_id: {
    url: `service-courseContent`,
    method: SERVICE_METHODS.DELETE,
  },
  active_inactive_course_content: {
    url: `service-courseContent`,
    method: SERVICE_METHODS.POST,
  },
  /// -------------------Time Slot------------------------
  post_time_slot: {
    url: `time-slot`,
    method: SERVICE_METHODS.POST,
  },
  get_time_slot_by_id: {
    url: `time-slot`,
    method: SERVICE_METHODS.GET,
  },

  // --------Events --------
  get_all_events: {
    url: `event`,
    method: SERVICE_METHODS.GET,
  },

  add_event: {
    url: `event`,
    method: SERVICE_METHODS.POST,
  },

  active_inactive_event: {
    url: `active-event`,
    method: SERVICE_METHODS.POST,
  },
  update_event: {
    url: `event`,
    method: SERVICE_METHODS.PUT,
  },
  delete_event: {
    url: `event`,
    method: SERVICE_METHODS.DELETE,
  },

  // --------Subscription package --------
  get_all_subscription: {
    url: `subscription-package`,
    method: SERVICE_METHODS.GET,
  },

  create_subscription: {
    url: `subscription-package`,
    method: SERVICE_METHODS.POST,
  },

  update_subscription: {
    url: `subscription-package`,
    method: SERVICE_METHODS.PUT,
  },
  delete_subscription: {
    url: `subscription-package`,
    method: SERVICE_METHODS.DELETE,
  },

  // --------Blog category ---------
  get_all_blog_categories: {
    url: `blog-category`,
    method: SERVICE_METHODS.GET,
  },
  create_blog_category: {
    url: `blog-category`,
    method: SERVICE_METHODS.POST,
  },
  update_blog_category: {
    url: `blog-category`,
    method: SERVICE_METHODS.PUT,
  },
  delete_blog_category: {
    url: `blog-category`,
    method: SERVICE_METHODS.DELETE,
  },
  active_inactive_blog_category: {
    url: `active-blogCategory`,
    method: SERVICE_METHODS.POST,
  },
  // ------Blog------
  get_all_blog: {
    url: `blog`,
    method: SERVICE_METHODS.GET,
  },
  create_blog: {
    url: `blog`,
    method: SERVICE_METHODS.POST,
  },
  get_blogs_category: {
    url: `blog-category`,
    method: SERVICE_METHODS.GET,
  },

  update_blog: {
    url: `blog`,
    method: SERVICE_METHODS.PUT,
  },
  delete_blog: {
    url: `blog`,
    method: SERVICE_METHODS.DELETE,
  },

  //------ user list ------
  get_all_users: {
    url: `users`,
    method: SERVICE_METHODS.GET,
  },
  delete_user: {
    url: `user`,
    method: SERVICE_METHODS.DELETE,
  },
  active_inactive_user: {
    url: `user`,
    method: SERVICE_METHODS.POST,
  },
  // ----------- System Configuration ----------

  get_system_configuration: {
    url: `menu-config`,
    method: SERVICE_METHODS.GET,
  },
  update_system_configuration: {
    url: `system-configurations`,
    method: SERVICE_METHODS.PUT,
  },
  //------------- trainer application ------------------------------
  get_all_trainer_applications: {
    url: `applications`,
    method: SERVICE_METHODS.GET,
  },
  get_trainer_application_by_id: {
    url: `application`,
    method: SERVICE_METHODS.GET,
  },
  update_trainer_application_status: {
    url: `application`,
    method: SERVICE_METHODS.POST,
  },

  //----------exception handling ------------
  add_error_handling_token: { url: `err`, method: SERVICE_METHODS.POST },
  add_error_handling_public_token: {
    url: `perr`,
    method: SERVICE_METHODS.POST,
  },
  // -------Grievances--------------
  get_all_grievances: {
    url: `grievances`,
    method: SERVICE_METHODS.GET,
  },
  add_grievance_conversation: {
    url: `grievances-conversation`,
    method: SERVICE_METHODS.POST,
  },
  update_grievance_status: {
    url: `grievances`,
    method: SERVICE_METHODS.PUT,
  },
  update_grievance_conversation: {
    url: `grievances-conversation`,
    method: SERVICE_METHODS.PUT,
  },
  mark_read_grievance: {
    url: "mark-read-grievances",
    method: SERVICE_METHODS.POST,
  },
  // ---------System Help ---------------
  get_all_system_help: {
    url: `system-help`,
    method: SERVICE_METHODS.GET,
  },

  create_system_help: {
    url: `system-help`,
    method: SERVICE_METHODS.POST,
  },

  update_system_help: {
    url: `system-help`,
    method: SERVICE_METHODS.PUT,
  },

  delete_system_help: {
    url: `system-help`,
    method: SERVICE_METHODS.DELETE,
  },

  active_inactive_system_help: {
    url: `system-help-active`,
    method: SERVICE_METHODS.POST,
  },
  // ----ddl----------------------
  global_drop_down: {
    url: `ddl`,
    method: SERVICE_METHODS.POST,
  },

  // ----bookings details--------
  get_all_bookings_details: {
    url: `bookings`,
    method: SERVICE_METHODS.GET,
  },
  get_bookings_details_by_id: {
    url: `booking`,
    method: SERVICE_METHODS.GET,
  },

  //--------------Payment Transaction---------------
  get_all_payment_transaction: {
    url: `payment-transaction`,
    method: SERVICE_METHODS.GET,
  },

  //------------Refresh Token-------------------
  renew_access_token: {
    url: `refresh-authorization-token`,
    method: SERVICE_METHODS.POST,
  },

  //----------- sessions ----------------------
  get_all_sessions: {
    url: `sessions`,
    method: SERVICE_METHODS.GET,
  },
  //----------- notification ----------------------
  send_notification: {
    url: `notification-send`,
    method: SERVICE_METHODS.POST,
  },

  // --------------inquiry -----------------------
  get_all_inquiry: {
    url: `inquiry`,
    method: SERVICE_METHODS.GET,
  },

  approve_inquiry: {
    url: `inquiry`,
    method: SERVICE_METHODS.PUT,
  },
  // ---------- Report ----------------------
  get_report_by_id: { url: `reports`, method: SERVICE_METHODS.GET },

  // ------------Change Password --------------------
  change_password: {
    url: `change-password`,
    method: SERVICE_METHODS.POST,
  },

  // ---------- image --------------------
  get_presigned_url: {
    url: `vppsobj`,
    method: SERVICE_METHODS.GET,
  },
  delete_presigned_url: {
    url: `remove-image`,
    method: SERVICE_METHODS.DELETE,
  },
  upload_image: {
    url: `upload-image`,
    method: SERVICE_METHODS.POST_CONFIG,
  },

  //-------------- newsLetter Subscription ------------
  get_all_newsletter_subscription: {
    url: `newsletter-subscription`,
    method: SERVICE_METHODS.GET,
  },

  //---------- payment due -------------------
  get_all_payment_due: {
    url: `payment-due`,
    method: SERVICE_METHODS.GET,
  },
  get_payment_due_summary: {
    url: `payment-due-summary`,
    method: SERVICE_METHODS.GET,
  },
  trainer_payout: {
    url: `generate-trainer-payout`,
    method: SERVICE_METHODS.POST,
  },
  get_all_payout_history: {
    url: `payout-history`,
    method: SERVICE_METHODS.GET,
  },

  //---------- system configuration -------------------

  get_system_version: {
    url: `sc`,
    method: SERVICE_METHODS.GET,
  },
};
