import translationEN from "../locales/en.json";
import {
  BOOKING_DATE_TYPE,
  BOOKING_STATUS,
  GENDER_LABEL,
  GENDER_VALUES,
  GRIEVANCE_STATUS,
  LIBRARY_STATUS_LABEL,
  LIBRARY_STATUS_VALUE,
  NOTIFICATION_SEND,
  PAYMENT_ENTITY_TYPE_ENUM,
  PAYMENT_TRANSACTION_TYPE,
  TARGET_AUDIENCE_HELP,
  TARGET_AUDIENCE_HELP_VALUES,
  TARGET_AUDIENCE_LABEL,
  TARGET_AUDIENCE_VALUES,
  TRAINER_APPLICATION_DOCUMENT_SECTION,
  TRAINER_APPLICATION_DOCUMENT_SECTION_STATUS,
  TRAINER_APPLICATION_STATUS,
  PAYMENT_TRANSACTION_STATUS_ENUM,
  PAYOUT_PAYMENT_STATUS,
  USER_GENDER_VALUES,
  USER_STATIC_ROLE,
} from "./AppEnumerations";
export const MESSAGE_LOCAL_UNKNOWN_ERROR_OCCURRED =
  "unknown_error_occurred_try_again";
export const MESSAGE_LOCAL_SUCCESS = "success";
export const DEFAULT_STATUS_CODE_UNKNOWN_ERROR = 500;
export const DEFAULT_STATUS_CODE_SUCCESS = 200;
export const DEFAULT_STATUS_CODE_CONSENT = 422;
export const DEFAULT_STATUS_CODE_UNAUTHORIZED = 401;
export const UNPROCESSABLE_ENTITY_CODE = 422;
export const FILE_NAME_MAX_LENGTH = 20;
export const MESSAGE_UNKNOWN_ERROR_OCCURRED = " UNKNOWN ERROR OCCURRED ";
export const MESSAGE_UNKNOWN_ERROR_DECRYPTION = "Decryption failed";
export const MESSAGE_TOKEN_EXPIRED = "TokenExpiredError";
export const ENCRYPTION_DECRYPTION_FAIL = 'EncryptionDecryptionFail';
export const DEFAULT_STATUS_CODE_NO_CONTENT = " ";
export const US_CURRENCY = "USD";
export const CAD_CURRENCY = "CAD";
export const INR_CURRENCY = "INR";

export const LANGUAGE_LIST = [
  {
    country_code: null,
    code: "en",
    name: "English",
    local_name: "English",
    path: translationEN,
  },
];

export const ORDER_BY = {
  desc: "DESC",
  asc: "ASC",
};

export const TOOL_TIP_POSITION = {
  top: 1,
  right: 2,
  bottom: 3,
  left: 4,
};

export const DEFAULT_CREATED_BY = "System Administrator";
export const DEFAULT_MODIFIED_BY = "System Administrator";

export const MONTH_NAMES = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const RADIO_BUTTON_LIBRARY_STATUS = {
  radioGroup: [
    { label: LIBRARY_STATUS_LABEL.Draft, value: LIBRARY_STATUS_VALUE.Draft },
    {
      label: LIBRARY_STATUS_LABEL.Published,
      value: LIBRARY_STATUS_VALUE.Published,
    },
    {
      label: LIBRARY_STATUS_LABEL.Discontinued,
      value: LIBRARY_STATUS_VALUE.Discontinued,
    },
  ],
};

//----------------basic info----------------
export const DAYS = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export const GENDER_OPTIONS = [
  { label: GENDER_LABEL.Male, value: GENDER_VALUES.Male },
  { label: GENDER_LABEL.Female, value: GENDER_VALUES.Female },
  { label: GENDER_LABEL.Both, value: GENDER_VALUES.Both },
];

export const TARGET_AUDIENCE_OPTION = [
  { label: TARGET_AUDIENCE_LABEL.User, value: TARGET_AUDIENCE_VALUES.User },
  {
    label: TARGET_AUDIENCE_LABEL.Trainer,
    value: TARGET_AUDIENCE_VALUES.Trainer,
  },
  {
    label: TARGET_AUDIENCE_LABEL.User_And_Trainer,
    value: TARGET_AUDIENCE_VALUES.User_And_Trainer,
  },
];
export const TARGET_AUDIENCE = [
  { label: TARGET_AUDIENCE_HELP.All, value: TARGET_AUDIENCE_HELP_VALUES.All },
  {
    label: TARGET_AUDIENCE_HELP.Trainer,
    value: TARGET_AUDIENCE_HELP_VALUES.Trainer,
  },
  {
    label: TARGET_AUDIENCE_HELP.User,
    value: TARGET_AUDIENCE_HELP_VALUES.User,
  },
];

export const TRAINER_APPLICATION_LABEL = {
  [TRAINER_APPLICATION_STATUS.Draft]: "Draft",
  [TRAINER_APPLICATION_STATUS.SubmittedForReview]: "Submitted For Review",
  [TRAINER_APPLICATION_STATUS.ReviewInProgress]: "Review In Progress",
  [TRAINER_APPLICATION_STATUS.ChangeRequested]: "Change Requested",
  [TRAINER_APPLICATION_STATUS.ScheduledInterview]: "Scheduled Interview",
  [TRAINER_APPLICATION_STATUS.Hold]: "Hold",
  [TRAINER_APPLICATION_STATUS.Rejected]: "Rejected",
  [TRAINER_APPLICATION_STATUS.Approved]: "Approved",
  [TRAINER_APPLICATION_STATUS.Archive]: "Archive",
  [TRAINER_APPLICATION_STATUS.Locked]: "Locked",
  [TRAINER_APPLICATION_STATUS.PartialApproved]: "Partial Approved",
};
export const BOOKING_DETAILS_LABEL = {
  [BOOKING_STATUS.AwaitingConfirmation]: "Awaiting Confirmation",
  [BOOKING_STATUS.BookingConfirmed]: "Booking Confirmed",
  [BOOKING_STATUS.Cancelled]: "Cancelled",
};

export const BOOKING_DATE_TYPE_LABEL = {
  [BOOKING_DATE_TYPE.ScheduleDate]: "Schedule Date",
  [BOOKING_DATE_TYPE.BookingDate]: "Booking Date",
};

export const BOOKINGS_DETAILS_STATUS = [
  {
    value: BOOKING_STATUS.AwaitingConfirmation,
    label: BOOKING_DETAILS_LABEL[BOOKING_STATUS.AwaitingConfirmation],
  },
  {
    value: BOOKING_STATUS.BookingConfirmed,
    label: BOOKING_DETAILS_LABEL[BOOKING_STATUS.BookingConfirmed],
  },
  {
    value: BOOKING_STATUS.Cancelled,
    label: BOOKING_DETAILS_LABEL[BOOKING_STATUS.Cancelled],
  },
];

export const BOOKINGS_DETAILS_DATE_TYPE = [
  {
    value: BOOKING_DATE_TYPE.ScheduleDate,
    label: BOOKING_DATE_TYPE_LABEL[BOOKING_DATE_TYPE.ScheduleDate],
  },
  {
    value: BOOKING_DATE_TYPE.BookingDate,
    label: BOOKING_DATE_TYPE_LABEL[BOOKING_DATE_TYPE.BookingDate],
  },
];
export const APPLICATION_STATUS_OPTION_FOR_STATUS_LOCKED = [
  {
    value: TRAINER_APPLICATION_STATUS.Draft,
    label: TRAINER_APPLICATION_LABEL[TRAINER_APPLICATION_STATUS.Draft],
  },
  {
    value: TRAINER_APPLICATION_STATUS.Archive,
    label: TRAINER_APPLICATION_LABEL[TRAINER_APPLICATION_STATUS.Archive],
  },
];

export const APPLICATION_STATUS_OPTION = [
  {
    value: TRAINER_APPLICATION_STATUS.Draft,
    label: TRAINER_APPLICATION_LABEL[TRAINER_APPLICATION_STATUS.Draft],
  },
  {
    value: TRAINER_APPLICATION_STATUS.SubmittedForReview,
    label:
      TRAINER_APPLICATION_LABEL[TRAINER_APPLICATION_STATUS.SubmittedForReview],
  },
  {
    value: TRAINER_APPLICATION_STATUS.ReviewInProgress,
    label:
      TRAINER_APPLICATION_LABEL[TRAINER_APPLICATION_STATUS.ReviewInProgress],
  },
  {
    value: TRAINER_APPLICATION_STATUS.ChangeRequested,
    label:
      TRAINER_APPLICATION_LABEL[TRAINER_APPLICATION_STATUS.ChangeRequested],
  },
  {
    value: TRAINER_APPLICATION_STATUS.ScheduledInterview,
    label:
      TRAINER_APPLICATION_LABEL[TRAINER_APPLICATION_STATUS.ScheduledInterview],
  },
  {
    value: TRAINER_APPLICATION_STATUS.Hold,
    label: TRAINER_APPLICATION_LABEL[TRAINER_APPLICATION_STATUS.Hold],
  },
  {
    value: TRAINER_APPLICATION_STATUS.PartialApproved,
    label:
      TRAINER_APPLICATION_LABEL[TRAINER_APPLICATION_STATUS.PartialApproved],
  },
  {
    value: TRAINER_APPLICATION_STATUS.Rejected,
    label: TRAINER_APPLICATION_LABEL[TRAINER_APPLICATION_STATUS.Rejected],
  },
  {
    value: TRAINER_APPLICATION_STATUS.Approved,
    label: TRAINER_APPLICATION_LABEL[TRAINER_APPLICATION_STATUS.Approved],
  },
  {
    value: TRAINER_APPLICATION_STATUS.Archive,
    label: TRAINER_APPLICATION_LABEL[TRAINER_APPLICATION_STATUS.Archive],
  },
  {
    value: TRAINER_APPLICATION_STATUS.Locked,
    label: TRAINER_APPLICATION_LABEL[TRAINER_APPLICATION_STATUS.Locked],
  },
];

export const TRAINER_APPLICATION_SECTION_REVIEW_LABEL = {
  [TRAINER_APPLICATION_DOCUMENT_SECTION.IdentityVerification]:
    "Identity Document Verification",
  [TRAINER_APPLICATION_DOCUMENT_SECTION.AddressVerification]:
    "Address Document Verification",
  [TRAINER_APPLICATION_DOCUMENT_SECTION.KeycodeVerification]:
    "Keycode Document Verification",
};

export const TRAINER_APPLICATION_SECTION_REVIEW_STATUS = {
  [TRAINER_APPLICATION_DOCUMENT_SECTION_STATUS.Approved]: "Approved",
  [TRAINER_APPLICATION_DOCUMENT_SECTION_STATUS.Rejected]: "Rejected",
};

export const GRIEVANCE_STATUS_LABEL = {
  [GRIEVANCE_STATUS.Pending]: "Pending",
  [GRIEVANCE_STATUS.InReview]: "Review In Progress",
  [GRIEVANCE_STATUS.Close]: "Close",
  [GRIEVANCE_STATUS.Hold]: "Hold",
  [GRIEVANCE_STATUS.Invalid]: "Invalid",
};

export const NOTIFICATION_SEND_LABEL = {
  [NOTIFICATION_SEND.All]: "All",
  [NOTIFICATION_SEND.trainer]: "Trainers",
  [NOTIFICATION_SEND.user]: "Users",
};

export const NOTIFICATION_SEND_OPTION = [
  {
    value: NOTIFICATION_SEND.All,
    label: NOTIFICATION_SEND_LABEL[NOTIFICATION_SEND.All],
  },
  {
    value: NOTIFICATION_SEND.trainer,
    label: NOTIFICATION_SEND_LABEL[NOTIFICATION_SEND.trainer],
  },
  {
    value: NOTIFICATION_SEND.user,
    label: NOTIFICATION_SEND_LABEL[NOTIFICATION_SEND.user],
  },
];

export const GRIEVANCE_STATUS_OPTION = [
  {
    value: GRIEVANCE_STATUS.InReview,
    label: GRIEVANCE_STATUS_LABEL[GRIEVANCE_STATUS.InReview],
  },
  {
    value: GRIEVANCE_STATUS.Close,
    label: GRIEVANCE_STATUS_LABEL[GRIEVANCE_STATUS.Close],
  },

  {
    value: GRIEVANCE_STATUS.Hold,
    label: GRIEVANCE_STATUS_LABEL[GRIEVANCE_STATUS.Hold],
  },
  {
    value: GRIEVANCE_STATUS.Invalid,
    label: GRIEVANCE_STATUS_LABEL[GRIEVANCE_STATUS.Invalid],
  },
];

export const GRIEVANCE_STATUS_VALUE_TABLE = {
  1: "Pending",
  2: "InReview",
  3: "Closed",
  4: "Hold",
  5: "Invalid",
};

export const MIN_MAX_LENGTH_COMPARE = {
  Zero: 0,
  One: 1,
  Thousands: 1000,
};

// payment transaction
export const PAYMENT_ENTITY_TYPE_LABEL = {
  [PAYMENT_ENTITY_TYPE_ENUM.Booking]: "Booking",
  [PAYMENT_ENTITY_TYPE_ENUM.LibraryContent]: "Library Content",
  [PAYMENT_ENTITY_TYPE_ENUM.Subscription]: "Subscription Package",
  [PAYMENT_ENTITY_TYPE_ENUM.event]: "Event Register",
  [PAYMENT_ENTITY_TYPE_ENUM.LibraryDirectory]: "Library Directory",
};
export const PAYMENT_TRANSACTION_TYPE_LABEL = {
  [PAYMENT_TRANSACTION_TYPE.Credit]: "Credit",
  [PAYMENT_TRANSACTION_TYPE.Debit]: "Debit",
};

export const PAYMENT_TRANSACTION_STATUS_LABEL = {
  [PAYMENT_TRANSACTION_STATUS_ENUM.Initiated]: "Initiated",
  [PAYMENT_TRANSACTION_STATUS_ENUM.Captured]: "Captured",
  [PAYMENT_TRANSACTION_STATUS_ENUM.Refunded]: "Refunded",
  [PAYMENT_TRANSACTION_STATUS_ENUM.Failed]: "Failed",
};

export const PAYMENT_ENTITY_TYPE_STATUS = [
  {
    value: PAYMENT_ENTITY_TYPE_ENUM.Booking,
    label: PAYMENT_ENTITY_TYPE_LABEL[PAYMENT_ENTITY_TYPE_ENUM.Booking],
  },
  {
    value: PAYMENT_ENTITY_TYPE_ENUM.LibraryContent,
    label: PAYMENT_ENTITY_TYPE_LABEL[PAYMENT_ENTITY_TYPE_ENUM.LibraryContent],
  },
  {
    value: PAYMENT_ENTITY_TYPE_ENUM.Subscription,
    label: PAYMENT_ENTITY_TYPE_LABEL[PAYMENT_ENTITY_TYPE_ENUM.Subscription],
  },
  {
    value: PAYMENT_ENTITY_TYPE_ENUM.event,
    label: PAYMENT_ENTITY_TYPE_LABEL[PAYMENT_ENTITY_TYPE_ENUM.event],
  },
  {
    value: PAYMENT_ENTITY_TYPE_ENUM.LibraryDirectory,
    label: PAYMENT_ENTITY_TYPE_LABEL[PAYMENT_ENTITY_TYPE_ENUM.LibraryDirectory],
  },
];

export const PAYMENT_TRANSACTION_TYPE_STATUS = [
  {
    value: PAYMENT_TRANSACTION_TYPE.Credit,
    label: PAYMENT_TRANSACTION_TYPE_LABEL[PAYMENT_TRANSACTION_TYPE.Credit],
  },
  {
    value: PAYMENT_TRANSACTION_TYPE.Debit,
    label: PAYMENT_TRANSACTION_TYPE_LABEL[PAYMENT_TRANSACTION_TYPE.Debit],
  },
];

export const PAYMENT_TRANSACTION_STATUS = [
  {
    value: PAYMENT_TRANSACTION_STATUS_ENUM.Initiated,
    label:
      PAYMENT_TRANSACTION_STATUS_LABEL[
        PAYMENT_TRANSACTION_STATUS_ENUM.Initiated
      ],
  },
  {
    value: PAYMENT_TRANSACTION_STATUS_ENUM.Captured,
    label:
      PAYMENT_TRANSACTION_STATUS_LABEL[
        PAYMENT_TRANSACTION_STATUS_ENUM.Captured
      ],
  },
  {
    value: PAYMENT_TRANSACTION_STATUS_ENUM.Refunded,
    label:
      PAYMENT_TRANSACTION_STATUS_LABEL[
        PAYMENT_TRANSACTION_STATUS_ENUM.Refunded
      ],
  },
  {
    value: PAYMENT_TRANSACTION_STATUS_ENUM.Failed,
    label:
      PAYMENT_TRANSACTION_STATUS_LABEL[PAYMENT_TRANSACTION_STATUS_ENUM.Failed],
  },
];

export const monthShortNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const CURRENCY_SIGN = "₹";

export const TRAINER_APPLICATION_STATUS_OPTIONS = {
  Locked: [
    {
      value: TRAINER_APPLICATION_STATUS.ReviewInProgress,
      label:
        TRAINER_APPLICATION_LABEL[TRAINER_APPLICATION_STATUS.ReviewInProgress],
    },
    {
      value: TRAINER_APPLICATION_STATUS.Archive,
      label: TRAINER_APPLICATION_LABEL[TRAINER_APPLICATION_STATUS.Archive],
    },
  ],
  ScheduleInterview: [
    {
      value: TRAINER_APPLICATION_STATUS.Rejected,
      label: TRAINER_APPLICATION_LABEL[TRAINER_APPLICATION_STATUS.Rejected],
    },
  ],
  ReviewInProgressAllApprove: [
    {
      value: TRAINER_APPLICATION_STATUS.Hold,
      label: TRAINER_APPLICATION_LABEL[TRAINER_APPLICATION_STATUS.Hold],
    },
    {
      value: TRAINER_APPLICATION_STATUS.Rejected,
      label: TRAINER_APPLICATION_LABEL[TRAINER_APPLICATION_STATUS.Rejected],
    },
  ],
  ReviewInProgress: [
    {
      value: TRAINER_APPLICATION_STATUS.Hold,
      label: TRAINER_APPLICATION_LABEL[TRAINER_APPLICATION_STATUS.Hold],
    },
    {
      value: TRAINER_APPLICATION_STATUS.Rejected,
      label: TRAINER_APPLICATION_LABEL[TRAINER_APPLICATION_STATUS.Rejected],
    },
  ],
};

export const PAYOUT_PAYMENT_STATUS_LABEL = {
  [PAYOUT_PAYMENT_STATUS.queued]: "Queued",
  [PAYOUT_PAYMENT_STATUS.pending]: "Pending",
  [PAYOUT_PAYMENT_STATUS.rejected]: "Rejected",
  [PAYOUT_PAYMENT_STATUS.processing]: "Processing",
  [PAYOUT_PAYMENT_STATUS.processed]: "Processed",
  [PAYOUT_PAYMENT_STATUS.cancelled]: "Cancelled",
  [PAYOUT_PAYMENT_STATUS.reversed]: "Reversed",
  [PAYOUT_PAYMENT_STATUS.failed]: "Failed",
};

export const PAYMENT_HISTORY_STATUS = [
  {
    value: PAYOUT_PAYMENT_STATUS.queued,
    label: PAYOUT_PAYMENT_STATUS_LABEL[PAYOUT_PAYMENT_STATUS.queued],
  },
  {
    value: PAYOUT_PAYMENT_STATUS.pending,
    label: PAYOUT_PAYMENT_STATUS_LABEL[PAYOUT_PAYMENT_STATUS.pending],
  },
  {
    value: PAYOUT_PAYMENT_STATUS.rejected,
    label: PAYOUT_PAYMENT_STATUS_LABEL[PAYOUT_PAYMENT_STATUS.rejected],
  },
  {
    value: PAYOUT_PAYMENT_STATUS.processing,
    label: PAYOUT_PAYMENT_STATUS_LABEL[PAYOUT_PAYMENT_STATUS.processing],
  },
  {
    value: PAYOUT_PAYMENT_STATUS.processed,
    label: PAYOUT_PAYMENT_STATUS_LABEL[PAYOUT_PAYMENT_STATUS.processed],
  },
  {
    value: PAYOUT_PAYMENT_STATUS.cancelled,
    label: PAYOUT_PAYMENT_STATUS_LABEL[PAYOUT_PAYMENT_STATUS.cancelled],
  },
  {
    value: PAYOUT_PAYMENT_STATUS.reversed,
    label: PAYOUT_PAYMENT_STATUS_LABEL[PAYOUT_PAYMENT_STATUS.reversed],
  },
  {
    value: PAYOUT_PAYMENT_STATUS.failed,
    label: PAYOUT_PAYMENT_STATUS_LABEL[PAYOUT_PAYMENT_STATUS.failed],
  },
];

export const USER_TYPE_LABEL = {
  [USER_GENDER_VALUES.Male]: "Male",
  [USER_GENDER_VALUES.Female]: "Female",
  [USER_GENDER_VALUES.Diverse]: "Diverse",
  [USER_GENDER_VALUES.Unknown]: "Unknown",
};

export const USER_TYPE_STATUS = [
  {
    value: USER_GENDER_VALUES.Male,
    label: USER_TYPE_LABEL[USER_GENDER_VALUES.Male],
  },
  {
    value: USER_GENDER_VALUES.Female,
    label: USER_TYPE_LABEL[USER_GENDER_VALUES.Female],
  },
  // As we have remove diverse option
  // {
  //   value: USER_GENDER_VALUES.Diverse,
  //   label: USER_TYPE_LABEL[USER_GENDER_VALUES.Diverse],
  // },
];

export const USER_STATIC_ROLE_LABEL = {
  [USER_STATIC_ROLE.Customer]: "Customer",
  [USER_STATIC_ROLE.Trainer]: "Trainer",
  [USER_STATIC_ROLE.SuperAdmin]: "Admin",
};

export const USER_ROLE_STATUS = [
  {
    value: USER_STATIC_ROLE.Customer,
    label: USER_STATIC_ROLE_LABEL[USER_STATIC_ROLE.Customer],
  },
  {
    value: USER_STATIC_ROLE.Trainer,
    label: USER_STATIC_ROLE_LABEL[USER_STATIC_ROLE.Trainer],
  },
  {
    value: USER_STATIC_ROLE.SuperAdmin,
    label: USER_STATIC_ROLE_LABEL[USER_STATIC_ROLE.SuperAdmin],
  },
];
