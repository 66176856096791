import { LOCAL_STORAGE_KEYS } from "./AppEnumerations";

export const localStorageUtils = {
  getAccessToken: () => {
    try {
      return localStorage.getItem(LOCAL_STORAGE_KEYS.AccessToken);
    } catch (e) {
      return null;
    }
  },
  setAccessToken: async (token: string) => {
    try {
      await localStorage.setItem(LOCAL_STORAGE_KEYS.AccessToken, token);
      return 1;
    } catch (e) {
      return 0;
    }
  },
  getRefreshToken: async () => {
    try {
      return await localStorage.getItem(LOCAL_STORAGE_KEYS.RefreshToken);
    } catch (e) {
      return null;
    }
  },
  setRefreshToken: async (token: string) => {
    try {
      await localStorage.setItem(LOCAL_STORAGE_KEYS.RefreshToken, token);
      return 1;
    } catch (e) {
      return 0;
    }
  },
  getUserInfo: async () => {
    try {
      const jsonStr = await localStorage.getItem(LOCAL_STORAGE_KEYS.UserInfo);
      if (jsonStr) {
        return JSON.parse(jsonStr);
      }
      return null;
    } catch (e) {
      return null;
    }
  },
  setUserInfo: async (jsonObject: Object) => {
    try {
      await localStorage.setItem(
        LOCAL_STORAGE_KEYS.UserInfo,
        JSON.stringify(jsonObject)
      );
      return 1;
    } catch (e) {
      return 0;
    }
  },
  removeAll: async () => {
    try {
      await Promise.all([
        localStorage.removeItem(LOCAL_STORAGE_KEYS.AccessToken),
        localStorage.removeItem(LOCAL_STORAGE_KEYS.UserInfo),
      ]);
      return 1;
    } catch (e) {
      return 0;
    }
  },
  removeAccessToken: () => {
    try {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.AccessToken);
      return 1;
    } catch (e) {
      return 0;
    }
  },
  removeRefreshToken: () => {
    try {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.RefreshToken);
      return 1;
    } catch (e) {
      return 0;
    }
  },
  setProfileName: (name: string) => {
    try {
      localStorage.setItem(LOCAL_STORAGE_KEYS.ProfileName, name);
      return 1;
    } catch (e) {
      return 0;
    }
  },
  getProfileName: () => {
    try {
      return localStorage.getItem(LOCAL_STORAGE_KEYS.ProfileName);
    } catch (e) {
      return 0;
    }
  },
  setProfileUrl: (name: string) => {
    try {
      localStorage.setItem(LOCAL_STORAGE_KEYS.ProfileUrl, name);
      return 1;
    } catch (e) {
      return 0;
    }
  },
  getProfileUrl: () => {
    try {
      return localStorage.getItem(LOCAL_STORAGE_KEYS.ProfileUrl);
    } catch (e) {
      return 0;
    }
  },
  setCryptoKey: (cryptoKey: string) => {
    try {
      localStorage.setItem(LOCAL_STORAGE_KEYS.CryptoKey, cryptoKey);
      return 1;
    } catch (e) {
      return 0;
    }
  },
  getCryptoKey: () => {
    try {
      return localStorage.getItem(LOCAL_STORAGE_KEYS.CryptoKey);
    } catch (e) {
      return null;
    }
  },
};
