import * as Yup from "yup";
import { localStorageUtils } from "../../../utils/LocalStorageUtil";

import { CRYPTO, LOG_IN } from "../../../services/Endpoints";
import { toastError, toastSuccess } from "../../../utils/AppFunctions";
import { NavigateFunction } from "react-router-dom";
import { TUseTranslationTfn } from "../../../data/AppType";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../../utils/AppConstants";
import { logApplicationError } from "../../../core-component/error-boundary/ErrorBoundaryController";

export interface ILoginForm {
  username: string;
  password: string;
}

export interface ICrypto {
  cryptoCipherIV: string;
  cryptoCipherKey: string;
  secretKey: string;
}

export const validationSchemaLogin = Yup.object({
  username: Yup.string().required("Username is required!"),
  password: Yup.string().required("Password is required!"),
});

export const initialValuesLogin = {
  username: "",
  password: "",
};

export const submitLogin = async (
  values: ILoginForm,
  navigation: NavigateFunction,
  t: TUseTranslationTfn
) => {
  try {
    const { username, password } = values;
    const payload = { username: username, password: password };
    let resultLogin;
    resultLogin = await LOG_IN(payload);
    if (resultLogin && resultLogin.code === DEFAULT_STATUS_CODE_SUCCESS) {
      toastSuccess(resultLogin?.message);
      navigation("/verifyOtp", { state: { username, password } });
    } else {
      toastError(resultLogin?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } catch (e: any) {
    logApplicationError(e);
    toastError(e?.resultLogin?.data.title || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const submitCrypto = async (data: ICrypto) => {
  try {
    const crypto = await CRYPTO(data);

    if (crypto && crypto.code === DEFAULT_STATUS_CODE_SUCCESS) {
      return true;
    } else {
      toastError(crypto.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
      return false;
    }
  } catch (error: any) {
    toastError(error?.message || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    return false;
  }
};
