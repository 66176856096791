// HOSTING ENVIRONMENT
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT as string;
export const APP_HOST_URL = process.env.REACT_APP_APP_HOST_URL as string;

// bucket url
export const BUCKET_URL = process.env.REACT_APP_BUCKET_URL;
export const APP_IMAGE_URL = process.env.REACT_APP_APP_IMAGE_URL;

// API
export const API_HOST = process.env.REACT_APP_API_HOST;
export const API_BASE_PATH = process.env.REACT_APP_API_BASE_PATH;
export const BASE_URL = API_HOST + "/" + API_BASE_PATH;
export const API_PUBLIC_AUTHORIZATION_TOKEN = process.env
  .REACT_APP_API_PUBLIC_AUTHORIZATION_TOKEN as string;

// APP ENVIRONMENT
export const APP_DEFAULT_LANGUAGE = process.env.REACT_APP_APP_DEFAULT_LANGUAGE;
export const PER_PAGE_ROW_LIST = process.env.REACT_APP_PER_PAGE_ROW_LIST
  ? process.env.REACT_APP_PER_PAGE_ROW_LIST.split(",").map(Number)
  : [5, 10, 25, 50];
export const PER_PAGE_ROWS = Number(process.env.REACT_APP_PER_PAGE_ROWS);

export const SESSION_ITA = Number(process.env.REACT_APP_SESSION_ITA);

export const SESSION_SIT = Number(process.env.REACT_APP_SESSION_SIT);

export const URL_PRIVACY_POLICY = process.env
  .REACT_APP_URL_PRIVACY_POLICY as string;
export const URL_TERMS_CONDITION = process.env
  .REACT_APP_URL_TERMS_CONDITION as string;

// SERVICE DURATION
export const MAX_SERVICE_DAYS = process.env
  .REACT_APP_MAX_ALLOWED_SERVICE_DURATION_DAYS as string;

export const MAX_SERVICE_HOURS = process.env
  .REACT_APP_MAX_ALLOWED_SERVICE_DURATION_HOURS as string;

// PUBLIC KEY
export const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY
? process.env.REACT_APP_PUBLIC_KEY.replace(/\\n/gm, "\n")
: '' as string;