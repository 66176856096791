import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ICTextInput from "../../../core-component/ICTextInput";
import { useFormik } from "formik";
import {
  initialValuesLogin,
  submitCrypto,
  submitLogin,
  validationSchemaLogin,
} from "./LoginControllers";
import UseTranslationHook from "../../../hooks/UseTranslationHook";
import FingerprintAnimation from "./LoginButtonAnimations";
import { eyeIcon, visibilityEyeIcon } from "../../../assets/icons/SvgIconList";
import { localStorageUtils } from "../../../utils/LocalStorageUtil";
import ICImageView from "../../../core-component/ic-image/ICImageView";
import {
  PUBLIC_KEY,
  URL_PRIVACY_POLICY,
  URL_TERMS_CONDITION,
} from "../../../config/AppConfig";
import { generateKeys } from "../../../utils/AppFunctions";

const LoginView = () => {
  const { t } = UseTranslationHook();
  const [showPassword, setShowPassword] = useState(false);
  const [localUserName, setLocalUserName] = useState("");

  useEffect(() => {
    const fetchStoredUserName = async () => {
      // Read username from local storage when component mounts
      const storedUserName = await localStorageUtils.getUserInfo();

      if (storedUserName !== null) {
        // Assuming storedUserName is an object with a 'username' property
        setLocalUserName(storedUserName);
      }
    };

    fetchStoredUserName();
  }, []);

  const navigation = useNavigate();
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validationSchema = validationSchemaLogin;
  const formikInitialValues = {
    ...initialValuesLogin,
    username: localUserName,
  };

  const formik = useFormik({
    initialValues: formikInitialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      submitLogin(values, navigation, t);
    },
  });

  const cryptoCipherKey = localStorageUtils.getCryptoKey();

  useEffect(() => {
    const initializeCrypto = async () => {
      if (!cryptoCipherKey) {
        const cryptoKey = generateKeys(PUBLIC_KEY);
        localStorageUtils.setCryptoKey(JSON.stringify(cryptoKey));
        await submitCrypto(cryptoKey);
      }
    };

    initializeCrypto();
  }, [cryptoCipherKey]);

  return (
    <div>
      <div>
        <div className="grid grid-cols-12 ">
          <div className="col-span-12 hidden md:flex md:col-span-6 lg:col-span-7 xl:col-span-8 h-screen w-full  items-center justify-center">
            <img
              className="h-full"
              src={require("../../../assets/image/Yoga_img4.jpg")}
              alt={t("poster_image")}
            />
          </div>
          <div className="col-span-12 md:col-span-6  lg:col-span-5 xl:col-span-4 h-screen flex items-center justify-center bg-[#efefef85]">
            <div className="">
              <div className="mx-6 sm:mx-6 md:mx-14 lg:mx-20 xl:mx-24 xxl:mx-28">
                <div className="flex w-full justify-center ">
                  <img
                    className="font-semibold text-4xl tracking-widest h-64 mb-4"
                    src={require("../../../assets/image/VP.svg").default}
                    alt={t("poster_image")}
                  />
                </div>
                <p className="font-bold text-3xl text-skin-login mt-4">
                  {t("log_in")}
                </p>
                <p className="text-start py-2 text-skin-login-message">
                  {t("log_in_message")}
                </p>
                <form className="rounded py-8" onSubmit={formik.handleSubmit}>
                  <div className="mb-4">
                    <ICTextInput
                      required
                      containerClassName="p-2.5"
                      label={t("user_name")}
                      name="username"
                      value={formik.values.username}
                      autoFocus
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      errorMessage={
                        formik.touched.username &&
                        formik.errors.username &&
                        formik.errors.username
                      }
                    />
                  </div>
                  <div>
                    <ICTextInput
                      required
                      containerClassName="p-2.5"
                      type={showPassword ? "text" : "password"}
                      label={t("Password")}
                      trailing={
                        showPassword ? (
                          <div
                            className="w-5 h-5"
                            onClick={togglePasswordVisibility}
                          >
                            {eyeIcon}
                          </div>
                        ) : (
                          <div
                            className="w-5 h-5"
                            onClick={togglePasswordVisibility}
                          >
                            {visibilityEyeIcon}
                          </div>
                        )
                      }
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      errorMessage={
                        formik.touched.password &&
                        formik.errors.password &&
                        formik.errors.password
                      }
                    />
                  </div>
                  <div className="items-center justify-center mt-8">
                    <button className="w-full" type="submit">
                      <FingerprintAnimation />
                    </button>
                  </div>
                </form>
              </div>
              <div className="bg-skin-insights-card pb-1 w-full xl:flex justify-center ">
                <div className="flex  justify-center">
                  <div className="">{t("copyright")} |</div>
                  <div>
                    <Link
                      to={`${URL_PRIVACY_POLICY}`}
                      target="_blank"
                      className="ml-1 text-skin-about-link"
                    >
                      {t("privacy_policy")}
                    </Link>{" "}
                    |
                  </div>
                  <div>
                    <Link
                      to={`${URL_TERMS_CONDITION}`}
                      target="_blank"
                      className="ml-1 text-skin-about-link"
                    >
                      {t("terms_and_condition")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoginView;
